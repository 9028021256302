





















































































import {Component, Prop, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterContract from '@/components/filters/FilterContract.vue'
import {Contract} from '@/model/resource/Contract'
import {ContractCollection} from '@/model/collection/ContractCollection'
import {ListContractSchema} from '@/schema/resource/Contract/ListContractSchema'
import {ExportContractSchema} from '@/schema/resource/Contract/ExportContractSchema'
import {Dapp} from '@/model/resource/Dapp'
import PersistContractView from '@/views/persist/PersistContractView.vue'

@Component({
  components: {PersistContractView, FilterToggle, FilterContract},
})
export default class DappContractList extends Mixins(MixinRouteMatch) {
  @Prop() dapp!: Dapp
  schema = new ListContractSchema()
  collection = new ContractCollection()
  isAddingOrEditing = false

  get getItems() {
    return [...this.collection.items, ...this.collection.editionList]
  }

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    this.collection.dapp = [this.dapp]
    this.collection.setPerPage(null)
    await this.$await.run('hardQuery', () => this.collection.listContract())
    this.$root.$on('dappSaved', this.persistPendindItem)
  }

  beforeDestroy() {
    this.$root.$off('dappSaved', this.persistPendindItem)
  }

  persistPendindItem(id: number) {
    this.collection.editionList.forEach(contract => {
      contract.idDappFk = id
      contract.persistContract()
    })
    this.collection.editionList = []
  }

  async removeItem(item: Contract) {
    await this.$dialog.remove()
    if (item.idContractPk) {
      await item.removeContract(this.dapp.$id, item.idContractPk)
      await this.collection.queryAsPage()
    } else {
      this.collection.editionList = this.collection.editionList.filter(
        ({hash, title}) => !(item.hash === hash && item.title === title)
      )
    }
  }

  async downloadXlsx() {
    const {ascending, orderBy, page, limit, ...params} = this.collection.params

    const coll = new ContractCollection().clearFilters().addFilter(params)

    await coll.listExportContract()
    this.$xlsx.downloadFromSchema(coll.items, new ExportContractSchema())
  }

  addNew() {
    this.isAddingOrEditing = true
  }

  goToList(contract: Contract) {
    this.collection.editionList.push(contract)
    this.isAddingOrEditing = false
  }
}
