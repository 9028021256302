/**
 * List Schema of SocialType
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {SocialType} from '@/model/resource/SocialType'

/* TODO: review generated schema */
export class ListSocialTypeSchema extends DefaultSchema {
  readonly name = 'ListSocialType'

  readonly fieldSet: FieldSet<SocialType> = {
    iconUrl: (schema): FieldComponent => ({
      is: Component.RenderImage,
      bind: {
        src: schema.model.iconUrl,
        alt: this.translateFrom(schema.fieldName),
        innerClass: 'h-16 bg-primary p-2',
      },
    }),
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
