/**
 * Export Schema of SocialType
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet} from '@simpli/meta-schema'
import {SocialType} from '@/model/resource/SocialType'

/* TODO: review generated schema */
export class ExportSocialTypeSchema extends DefaultSchema {
  readonly name = 'ExportSocialType'

  readonly fieldSet: FieldSet<SocialType> = {
    idSocialTypePk: schema => schema.model.idSocialTypePk,
    title: schema => schema.model.title,
    iconUrl: schema => schema.model.iconUrl,
    active: schema => $.filter.bool(schema.model.active),
  }
}
